@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&family=Roboto+Slab:wght@400;500;600;700&display=swap");
/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto Slab', serif; */

:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #524ae3;
  --blackPrime--: #0f172a;
  --pink--: #cb198e;
  --blackLight--: #1920325c;
  --grayPrime--: #8d8d8d;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #524ae3;
  --tablePrime--: #f8fafc;
  --tablePrime--: #e2e8f0;
  --scrollColor--: #b6b6b6;
  --headerPrime--: #f0f4f8;
  --fontPoppins--: "Poppins", sans-serif;
  --fontRoboto--: "Roboto Slab", serif;
  --TableColumnBold--: #334155;
  --TableFont--: #64748b;
}

/* ======================== Start Input CSS ======================== */

/* all Fields css */
.mobSidebar .mainMenu li a i {
  margin-left: -5px !important;
}
.prime-input {
  margin-bottom: 10px;
  position: relative;
}

.form-input {
  width: 100% !important;
  height: 48px !important;
  border: 1px solid var(--inputBorder--) !important;
  border-radius: 6px 0 0 6px;
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
  background-color: #dee2e694;
}

.form-input:focus {
  border: 1px solid var(--blackPrime--) !important;
  outline: none;
  background-color: #fefdff !important;
}

.prime-input label {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 500;
  color: var(--blackPrime--);
}

/* For Input Type Password */

.passHideShow {
  position: absolute;
  top: 40px;
  right: 12px;
  cursor: pointer;
}

/* For Input Type Search */

.searching {
  position: absolute;
  top: 14px;
  right: 12px;
  cursor: pointer;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  font-size: 16px;
}

/* For Input Type Number */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Type Radio And Check Box */
.form-check {
  position: relative;
  display: block;
  /* padding-left: 4.25rem; */
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-label {
  margin-bottom: 0;
}
.radioWidth {
  height: 280px !important;
}

.prime-input.radio,
.prime-input.checkbox {
  display: flex;
  align-items: center;
}

.prime-input.radio label,
.prime-input.checkbox label {
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  height: 18px !important;
  width: 18px !important;
}

.form-input[type="radio"],
.form-input[type="checkbox"] {
  accent-color: var(--blackPrime--);
}

/* For Input Type File */

.form-input[type="file"]::file-selector-button {
  margin-right: 10px;
  border: none;
  background: var(--blackPrime--);
  padding: 0px 20px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  height: 47px;
}

.form-input[type="file"] {
  padding: 0px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.errorMessage {
  font-size: 14px;
  color: red;
}
@media screen and (max-width: 425px) {
  .form-input[type="file"]::file-selector-button {
    margin-right: 10px;
    border: none;
    background: var(--blackPrime--);
    padding: 0 5px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    height: 35px;
  }
  .form-input[type="file"]{
    height: 35px !important;
  }
}

/* ======================== End Input CSS ======================== */

/* ======================== Start Button CSS ======================== */

.themeBtn {
  border: 0;
  padding: 10px;
}
.searchBtn {
  border-radius: 0 6px 6px 0 !important;
}
.bgsuccess {
  background-color: rgb(25, 135, 84);
}
.searchBarBorder {
  border-right: none !important;
}
.searchBarBorder:focus {
  border-right: none !important;
  border: 1px solid rgb(166, 163, 163) !important;
}
/* Button Background Color CSS */

.btnPrime {
  background-color: var(--btnPrime--) !important;
  color: var(--white--) !important;
}

.btnBlackPrime {
  background-color: var(--blackPrime--) !important;
  color: var(--white--) !important;
}

/* ======================== End Button CSS ======================== */

/* ======================== Start Toggle Switch CSS ======================== */

.checkbox {
  display: none;
}

.slider {
  width: 60px;
  height: 30px;
  background-color: lightgray;
  border-radius: 20px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 4px solid transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  box-shadow: 0 0 10px 0 rgb(0, 0, 0, 0.25) inset;
  cursor: pointer;
}
.Mui-checked {
  color: #000000 !important;
}
.Mui-checked + .MuiSwitch-track {
  background-color: #000 !important;
}

/* ======================== End Toggle Switch CSS ======================== */
/* ======================== Start Table CSS ======================== */

.primeHeader {
  /* border-bottom: 1px solid var(--grayPrime--); */
  padding: 0px 30px;
  /* background-color: var(--headerPrime--); */
}

.titlePath {
  font-weight: 500;
}

.primeMain {
  /* min-height: 75vh;
  max-height: 75vh; */
  /* overflow: auto; */
  /* margin-top: 10px; */
  font-size: 14px;
  /* border-radius: 15px; */
  /* padding: 0 20px; */
  font-family: var(--fontPoppins--);
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableMain {
  /* background-color: white; */
  border-radius: 16px !important;
  /* width: fit-content; */
  /* overflow: overlay;
  margin: 0px 18px; */

  overflow: scroll;
  margin: 0px 18px;
  max-height: 660px;
}

/* .primeTable {} */

.primeTable tr th {
  background-color: inherit;
  padding: 12px 16px;
  font-family: var(--fontPoppins--);
  font-size: 14px;
  font-weight: 600;
}

/* .primeTable tr td {
  padding: 12px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  font-size: 15px;
  font-weight: 400;
} */
tbody tr td {
  padding: 8px 10px;
  /* border-bottom: 1px solid #cfcfcf; */
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  /* font-size: 15px; */
  font-weight: 400;
  background-color: white;
}
tbody tr td:first-child,
thead tr th:first-child {
  border-radius: 10px 0 0 10px;
}

tbody tr td:last-child,
thead tr th:last-child {
  border-radius: 0 10px 10px 0;
}
table.primeTable {
  border-collapse: separate !important;
  border-spacing: 0 10px !important; /* Adjust the 10px value to control the spacing */
}
thead tr td {
  padding: 12px 20px;
  border-bottom: 1px solid #cfcfcf;
  font-family: "Poppins", sans-serif;
  color: var(--TableFont--);
  /* font-size: 15px; */
  font-weight: 400;
}
thead {
  background-color: #e2e8f0;
}
.tableBoldFont {
  color: var(--TableColumnBold--);
  /* font-size: 14px;  */
  font-weight: 500;
}

/* pagination CSS */
.paginationFooter {
  /* position: sticky; */
  bottom: 0;
}
.pagination {
  width: 100%;
  /* background-color: var(--white--); */
  padding: 14px 15px;
  border-radius: 20px;
}

.paginationBtn:disabled {
  opacity: 0.8;
}

/* ================================================Pagination CSS=========================================== */
.pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  overflow: auto;
}
.pageOption {
  padding: 5px 6px;
  border-radius: 10%;
}
.count {
  margin: 15px;
}
.page-btn {
  height: 40px;
  width: 40px;
  margin: 5px;
  /* padding: 8px 17px; */
  border-radius: 5px;
  background-color: #e2e8f0;
  color: #000;
  border: none;
  opacity: 0.5;
}
.active {
  height: 35px;
  width: 35px;
  margin: 5px;
  /* padding: 10px 17px; */
  border-radius: 5px;
  background-color: var(--bs-primary);
  color: #cfcfcf;
  border: none;
}
.active-btn {
  height: 50px;
  width: 50px;
  margin: 5px;
  /* padding: 10px 15px; */
  border-radius: 50%;
  /* background-color: #494594; */
  color: #000;
  border: none;
}
.pagination .makeStyles-root-29 {
  display: flex !important;
}
/* Pagination Selection */

.tableSelection {
  margin-right: 15px;
  padding: 5px;
}

/* Pagination Selection */

.tableSelection {
  margin-right: 15px;
  padding: 5px;
}

/* Dialogue CSS */

.mainDialogue {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #00000050;
  opacity: 0;
  animation: fade-in 0.1s ease-in;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.1s ease-in;
}


@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Dialogue {
  width: 600px;
  min-height: 290px;
  background-color: var(--white--);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 111;
  border-radius: 10px;
}
.faeHostDialog{
  
    max-width: 65%;
    min-height: 290px;
    background-color: var(--white--);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 111;
    border-radius: 10px;
    max-height: 90vh;
  
}

@media screen and (max-width: 630px) {
  .Dialogue {
    width: 97%;
  }
}

/* Header */
.dialogueHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}

.headerTitle {
  font-size: 22px;
}

.closeBtn {
  background-color: var(--blackPrime--);
  color: var(--white--);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

/* Main */
.dialogueMain {
  padding: 30px;
  position: relative;
}
.w83{
  width: 84%;
}

/* Footer */
.dialogueFooter {
  padding: 20px;
  display: flex;
  justify-content: end;
  align-items: center;
}
@media screen and (max-width: 426px) {
  .dialogueFooter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* ======================== End Table CSS ======================== */

/* ======================== Start Scroll CSS ======================== */
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
/* width */
::-webkit-scrollbar {
  height: 2px;
  width: 5px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollColor--);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  transition: all 5s ease-in-out;
  background: var(--scrollColor--);
}

/* ======================== End Scroll CSS ======================== */

/* ======================== Start Setting Box CSS ======================== */

.settingMain {
  padding: 20px;
  overflow: auto;
  /* height: 82vh; */
}

.settingBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0 0 4px #0000005c; */
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}
.loginType {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0 0 4px #0000005c; */
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}
.bgSetting {
  background-color: #fff;
}

.settingInnerBox .prime-input {
  margin-bottom: 5px !important;
}

.settingBoxHeader {
  margin-bottom: 15px;
}

.mainTitle {
  font-size: 22px;
  font-weight: bold;
}

/* ======================== End Setting Box CSS ======================== */

/* ======================== Start Dashboard Box CSS ======================== */
.mainDashboard {
  padding: 30px;
}

.dashboardBox {
  padding: 15px;
  background-color: #ededed;
  border-radius: 6px;
  box-shadow: -2px 2px 8px #00000030;
}

.dashIcon {
  font-size: 40px;
}

.dName {
  font-size: 20px;
}

.dCount {
  font-size: 40px;
  line-height: 40px;
}

.showMoreBtn {
  margin-top: 10px;
}
/* extra */

.description {
  width: calc(100% - 160px) !important;
}
#description {
  width: calc(100% - 160px) !important;
}
.cursor {
  cursor: pointer !important;
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.loader2 {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ======================== End Dashboard Box CSS ======================== */


.totalDollar {
  border: 1px solid darkgray;
  padding: 12px;
  border-radius: 0 10px 10px 0px;
  border-left: none;
}
.totalTex {
  /* border: 1px solid; */
  padding: 13px;
  border-radius: 10px 0 0 10px !important;
  background: rgba(0, 0, 0, 0.83);
  color: white;
  border-radius: 10px;
}

.userBtn {
  border-radius: 5px;
  margin: auto;
  width: 40px;
}


/* ======================== IMPORT IN STYLE CSS ======================== */
/* Table CSS */
/* .userMain table{
  position: relative;
}
.userMain .pagination {
  position: sticky;
  bottom: 0;
  height: 82px;
  background-color: #f1f5f9;
  width: 100%;
  margin: 0px;
} */
.userMain thead{
  position: sticky;
  top: 0;
  z-index: 1;
  height: 45px;
}
 /* fake host dialogue */
 .setImage {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 190px;
  /* overflow-y: auto; */
}

.img-container {
  position: relative;
}

.img-container i {
  position: absolute;
  right: 18px;
  top: 4px;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.scroll-content {
  display: flex;
  align-items: center;
  height: 100%;
  /* overflow-y: auto  ; */
}
::-webkit-scrollbar {
  display: none;
}

.switchTitleColor{
  color: #64748b;
}
.p2{
  padding: 12px;
}

