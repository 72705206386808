@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
/* font-family: 'Montserrat', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@500,600,700&display=swap");
/* font-family: 'Nunito Sans', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
/* font-family: 'Open Sans', sans-serif; */

:root {
  --black--: #000;
  --white--: #fff;
  --prime--: #524ae3;
  --blackPrime--: #0f172a;
  --grayPrime--: #8d8d8d;
  --inputBorder--: #cbd5e1;
  --btnPrime--: #524ae3;
  --gray--: #656565;
  --pink--: #cb198e;
}

/* ========================================= Login Page CSS ========================== */
.fontLogin {
  color: #ff7500 !important;
}
.blackPrime {
  background-color: #0f172a;
}

.mainLoginPage {
  display: flex;
  justify-items: center;
  width: 100% !important;
  height: 100vh !important;
  background: url(../images/w1.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.mainLoginPage .loginDiv {
  /* border-radius: 16px; */
  width: 440px;
  margin: auto;
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important; */
  overflow: hidden;
  top: 220px;
  right: 120px;
  position: absolute;
}

@media screen and (max-width: 768px) and (min-width : 426px) {
  .mainLoginPage .loginDiv {
    /* top: 105px; */
    left:137px;
  } 
  .mainLoginPage {
    display: flex;
    justify-items: center;
    width: 100% !important;
    height: 100vh !important;
    background: none !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #fff !important;
  }
}


@media screen and (max-width: 2560px) and (min-width : 1025px) {
  .mainLoginPage .loginDiv {
    top: 345px;
    right: 645px;
  }
}
@media screen and (max-width: 1920px) and (min-width : 1441px){
  .mainLoginPage .loginDiv {
    top: 250px;
    right: 395px;
  }
}
@media screen and (max-width: 1440px) and (min-width : 1024px){
  .mainLoginPage .loginDiv {
    top: 185px;
    right: 215px;
  }
}
@media screen and (max-width: 425px) {
  .mainLoginPage .loginDiv {
    /* top: 345px; */
    left: 0px;
    width: inherit;
  }
  .mainLoginPage {
    display: flex;
    justify-items: center;
    width: 100% !important;
    height: 100vh !important;
    background: none !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #fff !important;
  }

}
@media screen and (max-width: 1024px) and (min-width : 769px) {
  .mainLoginPage .loginDiv {
    top: 170px;
    right: 65px;
  }
}

.mainLoginPage .loginPage {
  background-color: initial;
  padding: 40px;
}
.mainLoginPage .form-input {
  width: 100% !important;
  /* height: 60px !important; */
  border: 1px solid var(--inputBorder--) !important;
  border-radius: 6px;
  padding: 16px;
  font-size: 16px;
  cursor: pointer;
}

.mainLoginPage .form-input:focus {
  border: 2px solid #f7a901bb !important;
  border-left: none !important;
  outline: none;
  background-color: #fefdff !important;
}
.mainLoginPage .loginLogo {
  height: 100px;
  width: 100px;
  background-color: var(--white--);
  border-radius: 50%;
}
.logoBar {
  height: 120px;
  /* background-color: var(--blackPrime--); */
  padding: 20px;
}
.loginIcons {
  border-radius: 6px 0 0 6px;
  width: 50px;
}
.loginFields {
  border-radius: 0 6px 6px 0 !important;
}
.sigIn-btn {
  border: 1px solid var(--inputBorder--) !important;
}

.forgetPassword {
  /* color: var(--prime--) !important; */
  cursor: pointer;
}

/* ========================================= Navbar Page CSS ========================== */
/* .boxNav{
  cursor: pointer;
  margin: 0 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 1px;
  position: static;
  transition: 0.3s;
 z-index: 11;
} */

.mainNavbar {
  position: -webkit-sticky;
  position: sticky;
  transition: 0.3s !important;
  z-index: 1000001;
  top: 0;
  background-color: #191919;
}
.boxNav{
  cursor: pointer;
  padding: 9px 12px;
 

}

.navBar {
  -webkit-backdrop-filter: saturate(180%) blur(8px);
  backdrop-filter: saturate(180%) blur(8px);
  background-color: #454545;
  border-radius: 15px;
  box-shadow: 0 0 10px #00000013;
  font-size: 18px;
  /* margin: 0 10px; */

}
.mainContainer {
  height: 100vh;
  overflow: hidden;
}

.containerRight {
  height: 100vh;
  overflow: scroll;
}
.mainAdmin {
  /* margin-top: 20px; */
  transition: 0.3s;
  height: 88vh;
  background: #F1F5F9;
  border-radius: 1rem 1rem 0rem 0rem;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  
}


/* Nav Menu Toggle JS CSS */
 /* .webNav {
  margin-left: 220px;
}

.mobNav {
  margin-left: 75px;
} */

/* Mobile Size */
 /* @media screen and (max-width: 992px) {
  .webNav {
    margin-left: 75px;
  }

  .mobNav {
    margin-left: 220px;
  }
}   */

/* ========================================= Sidebar Page CSS ========================== */

/* Side Menu Toggle JS CSS */
.webSidebar {
  width: 258px;
}

.mobSidebar {
  width: 83px;
}

.sideBar.mobSidebar .sideBarLogo span {
  display: none;
}

.navToggle {
  position: relative;
}

.sideBar.mobSidebar .mainMenu li a > div > span,
.sideBar.mobSidebar .mainMenu > li > a > i,
.sideBar.mobSidebar .subMenu li a span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a {
  justify-content: start;
}

.sideBar.mobSidebar .mainMenu li a i {
  margin: 0;
}

.sideBar.mobSidebar .subMenu li a {
  padding-left: 22px;
}

/* End Side Menu Toggle JS CSS */

/* Mobile Size */
@media screen and (max-width: 992px) {
  .webSidebar {
    width: 83px;
  }

  .sideBar .sideBarLogo span {
    display: none;
  }

  .sideBar .mainMenu li a > div > span,
  .sideBar .mainMenu > li > a > i,
  .sideBar .subMenu li a span {
    display: none;
  }

  .sideBar .mainMenu li a {
    justify-content: center;
  }

  .sideBar .mainMenu li a i {
    margin: 0;
  }

  .sideBar .subMenu li a {
    padding-left: 20px;
  }

  /* ============ */

  .mobSidebar {
    width: 220px;
  }

  .sideBar.mobSidebar .sideBarLogo span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div > span,
  .sideBar.mobSidebar .mainMenu > li > a > i,
  .sideBar.mobSidebar .subMenu li a span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div {
    display: flex;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu > li > a {
    justify-content: space-between;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu li a i {
    margin-right: 10px;
  }

  .sideBar.mobSidebar .subMenu li a {
    padding-left: 30px;
  }
}

.rotate90 {
  transform: rotate(90deg);
  transition: 0.3s;
}

.mainSidebar {
  position: relative;
}
.navigation::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}
.navigation::-webkit-scrollbar {
  width: 0px;
}
.sideMenuBg {
  position: absolute;
  top: 10px;
  right: 0px;
  fill: #f1f5f9;
}
.toggleIconNav {
  position: absolute;
  right: -13px;
  top: 50px;
  overflow: visible !important;
  transition: 0.3s;
  z-index: 2000;
}
.toggleIcon {
  transition: transform 0.3s;
}

.rotated {
  transform: rotate(90deg);
  transition: 0.3s;
}

.sideBar {
  height: 100vh;
  /* background-color: var(--white--) !important; */
  background-color: #191919;
  color: var(--gray--) !important;
  transition: .3s;
  /* overflow: hidden; */
}
.abb {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-right: 100px solid red;
  border-bottom: 50px solid transparent;
}

.sideBarLogo {
  /* border-bottom: 1px solid #434343; */
  padding: 24px;
}

.navigation {
  padding: 0px 10px;
  max-height: 83vh;
  margin-top: 40px;
  overflow-y: scroll !important
}

.mainMenu > li {
  padding-bottom: 5px;
}

.mainMenu li a {
  color: var(--grayPrime--);
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  align-items: center;
  margin-bottom: 5px;
}
.mainMenu li > a svg path {
  transition: 0.3s;
  fill: var(--grayPrime--);
}
.mainMenu li > a:hover,
.mainMenu .activeMenu {
  color: #ffffff;
  /* box-shadow: 0 0 5px; */
}
.mainMenu li > a:hover svg path,
.mainMenu .activeMenu svg path {
  fill: #ffffff;
}

.mainMenu li a i {
  margin-right: 10px;
  transition: 0.3s;
  height: 20px;
  width: 20px;
}
.mainMenu li a img {
  margin-right: 10px;
  transition: 0.3s;
  width: 17px;
  height: 17px;
}
.mainMenu li a svg {
  margin-right: 10px;
  transition: 0.3s;
  /* width: 17px;
  height: 17px; */
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

/* 
.content.open {
  max-height: 100px;
} */
/* 
 .transform0 {
  transform: scaleY(0);
  transition: height 0.3s ease;
  transform-origin: top;
  height: 0;
} 

.transform1 {
  transform: scaleY(1);
  transition: height 0.3s ease;
  transform-origin: top;
  height: auto;
} */
/* .subMenu-enter {
  max-height: 0;
  overflow: hidden;
}

.subMenu-enter-active {
  max-height: 1000px; /* Set a value large enough to accommodate submenu content 
  transition: max-height 0.3s ease; /* Adjust the duration and easing as needed 
}

.subMenu-exit {
  max-height: 1000px; /* Set a value large enough to accommodate submenu content 
}

.subMenu-exit-active {
  max-height: 0;
  transition: max-height 0.3s ease; /* Adjust the duration and easing as needed 
} */


.subMenu li a {
  justify-content: start;
  padding-left: 30px;
}

.subMenu li a i {
  font-size: 6px;
  display: flex;
  align-items: center;
}

/* ================================== Admin CSS =================================== */
/* 
.mainAdmin {
  margin-left: 230px;
  margin-top: 10px;
  margin-right: 10px;
  height: 100vh;
  background-color: var(--white--);
  transition: 0.3s;
} */

/* .mobAdmin {
  margin-left: 85px;
} */

/* Mobile Size */
@media screen and (max-width: 425px) {
  .pageCenterMob {
    display: flex;
    justify-content: center;
  }
  .pageSm{
    margin-top: 8px;
  }
  .smBTN{
    font-size: 12px;
    width: 47%;
    padding: 0%;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) { 
  .fake-host-page .searching-box{
       width: 100%;
  }
}
/* ================================== GIFT & GIFT CATEGORY CSS  =================================== */

/*      CARD    */

.card {
  word-wrap: break-word;
  background-clip: border-box;
  /* background-color: #fff; */
  border: 1px solid transparent;
  border-radius: 0.3rem;
  /* box-shadow: 0 0.76rem 1.52rem rgba(18, 38, 63, 0.03); */
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  min-width: 0;
  position: relative;
  width: 100%;
  border-top: none;
  box-shadow: 0px 14px 40px -2px lightgray;
}

.add-button {
  background-color: "#D9386A" !important;
}

.edit-button {
  background-image: linear-gradient(
    to left,
    #48d6a8 0,
    #029666 100%
  ) !important;
}

.delete-button {
  background-image: linear-gradient(45deg, #d9386a, #f7778c) !important;
}

.info-button {
  background-image: linear-gradient(
    to right,
    #46b7eb 0,
    #8cd8eb 100%
  ) !important;
}

.categoryImage {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.copyCode:hover {
  color: rgb(225, 78, 132);
}

/* ===================================== Host Info Css ================================= */

.hostProfileMain {
  padding: 20px;
}

.profile-image {
  /* box-shadow: 0 0 15px #a2a1a1; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  /* width: 360px;
  height: 275px; */
  margin-left: 50px;
  max-width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .profile-image {
    height: 200px !important;
  }
  .hostImages img {
    height: 100px !important;
    width: 100px !important;
  }
}
@media screen and (max-width: 768px) {
  .profile-image {
    margin-left: 0 !important;
  }
}

.MuiRadio-colorSecondary.Mui-checked {
  color: rgb(54, 57, 148) !important;
}
.hostImages img {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 10px;
  max-width: 100%;
}

/* ================================== USER INFO CSS  =================================== */

.gradient-custom {
  /* fallback for old browsers */
  background: #f6d365;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right bottom,
    rgba(246, 211, 101, 1),
    rgba(253, 160, 133, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right bottom,
    rgba(246, 211, 101, 1),
    rgba(253, 160, 133, 1)
  );
}
.profile {
  max-width: 100%;
  justify-content: center;
  display: flex;
}
.userData {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 320px) {
  .profileImage {
    width: 110px !important;
    height: 110px !important;
    border-width: 1px !important;
  }
  .profileImage img {
    height: 110px !important;
    width: 110px !important;
  }
}

.imageCard,
.allDetails {
  border-radius: 10px;
}
.uTable tr:not(:last-child) {
  border-bottom: 1px solid #e6ebf1;
}

/* .uTable tr td:first-child {
  color: #664dc9;
} */

.uTable td {
  padding: 15px 0px !important;
  /* font-size: 13px; */
  /* font-weight: bold; */
}
.styles-module_Editext__button__sxYQX {
  max-width: 0px !important;
  padding: 0 !important;
  font-size: 12px !important;
  border-radius: 3px !important;
  border-width: 0px !important;
  border-style: none !important;
  margin-left: 4px;
  background-color: #eaeaea;
}

.styles-module_Editext__input__2-M50 {
  height: 0px;
  width: 50% !important;
}
.profileImage {
  width: 150px;
  height: 150px;
  border-width: 1px !important;
}

.profileImage img {
  object-fit: cover;
  width: 150px;
  height: 150px;
}

.hostProfileImage {
  border-radius: 50%;
  overflow: hidden;
}

/* ========================= Loader css==================== */

.mainLoaderBox {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000070;
  width: 100%;
  height: 100%;
  z-index: 111111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #ffffff;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@media screen and (max-width: 320px) {
  .category-btn .btn-icon {
    padding: 3px 6px !important;
  }
  .category-btn button > i {
    font-size: 12px !important;
  }
}

/* ==================== Admin Profile css ================= */

.adminProfileBox {
  margin: 10px 0;
}
.dashBoxData{
  border-radius: 15px;
  /* box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgba(64, 64, 64, 0.4) !important; */
  padding: 10px;
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}
.adminProfileImg {
  padding: 30px;
}
.adminImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

/*======================== Dashboard css ====================  */

.dashIconBox {
  /* background-color: #e9e9e9; */
  /* padding: 25px; */
  font-size: 50px;
  background-image: url("../images/bgdark.png");
}

.dashBox {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
}

.icon {
  z-index: 111;
}
.icon-data1 {
  background-color: #C0EBFE;
  color: #008ecd;
}
.icon-data2 {
  background-color: #D4DBFD;
  color: #4860df;
}
.icon-data3 {
  background-color: #FFDBE6;
  color: #E75448;
}
.icon-data4 {
  background-color: #D6FFD0;
  color: #4CA040;
}

.dashDataBox{
  border-radius: 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}
.lineCss{
  opacity: 0.1;
  width: 85%;
  margin: 0 auto;
}
.chart1{
  border-radius: 15px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
}
.chartHeader{
  box-shadow: 0px 10px 10px -15px #111;
}

/*  fake host dialogue */
.excel-sheet-table th {
  white-space: nowrap;
}

.host-edit-multipleImg{
  display: flex;
  flex-direction: column;

}
.host-edit-multipleImg i{
  width: 84px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -5px;
  z-index: 111;
  font-size: 20px;
  cursor: pointer;
  color: #d93030;
}

@media screen and (min-device-width: 768px) and (max-device-width: 991px) { 
  .dashboard-activity{
    display: flex;
    grid-template-columns: auto auto auto auto;
    gap: 16px;
    width: 100%;
  }
  
  .dashboard-activity-one{
    /* margin-top: 16px; */
    padding-right: 8px;
  }
  .dashboard-activity-one,.dashboard-activity-two{
    width: 100%;
  }
  .dashboard-activity-two{
    padding-left: 8px;
  }
}